import React from 'react';
import {DocumentTemplateElement, TextInputElement} from 'types/documentTemplateElement';
import TextInputProperties from './TextInputProperties';

interface RenderElementPropertiesProps {
    element: DocumentTemplateElement;
    updateElement: (updatedElement: DocumentTemplateElement) => void;
}

const RenderElementProperties: React.FC<RenderElementPropertiesProps> = ({
                                                                             element,
                                                                             updateElement,
                                                                         }) => {
    switch (element.type) {
        case 'TextInput':
            return (
                <TextInputProperties
                    element={element as TextInputElement}
                    updateElement={updateElement}
                />
            );
        default:
            return (
                <p className="text-slate-400 text-sm font-medium">
                    This element doesn't have any properties
                </p>
            )
    }
};

export default RenderElementProperties;
