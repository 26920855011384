import React from 'react';
import {DocumentElement, TextBlockElement, TextInputElement} from 'types/documentTemplateElement';
import TextInputElementComponent from "./TextInputElementComponent";
import TextBlockElementComponent from "./TextBlockElementComponent";

interface RenderElementProps {
    element: DocumentElement;
    updateElement: (updatedElement: DocumentElement) => void;
}

const RenderElement: React.FC<RenderElementProps> = ({ element, updateElement }) => {
    switch (element.type) {
        case 'TextBlock':
            return (
                <TextBlockElementComponent
                    element={element as TextBlockElement}
                    updateElement={updateElement as (updatedElement: TextBlockElement) => void}
                />
            );
        case 'TextInput':
            return (
                <TextInputElementComponent
                    element={element as TextInputElement}
                    updateElement={updateElement as (updatedElement: TextInputElement) => void}
                />
            );
        // Agrega otros casos aquí
        default:
            return null;
    }
};

export default RenderElement;
