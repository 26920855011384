import React, {useState, useRef, useEffect} from 'react';
import InputMask from 'react-input-mask';
import Flag from 'react-world-flags';

interface Country {
    code: string;
    dialCode: string;
}

const countries: Country[] = [
    {code: "US", dialCode: "+1"},
    //{code: "AF", dialCode: "+93"},
    {code: "AL", dialCode: "+355"},
    {code: "DZ", dialCode: "+213"},
    {code: "AD", dialCode: "+376"},
    {code: "AO", dialCode: "+244"},
    {code: "AR", dialCode: "+54"},
    {code: "AM", dialCode: "+374"},
    {code: "AU", dialCode: "+61"},
    {code: "AT", dialCode: "+43"},
    {code: "AZ", dialCode: "+994"},
    {code: "BH", dialCode: "+973"},
    {code: "BD", dialCode: "+880"},
    {code: "BY", dialCode: "+375"},
    {code: "BE", dialCode: "+32"},
    {code: "BZ", dialCode: "+501"},
    {code: "BJ", dialCode: "+229"},
    {code: "BO", dialCode: "+591"},
    {code: "BR", dialCode: "+55"},
    {code: "BG", dialCode: "+359"},
    {code: "BF", dialCode: "+226"},
    {code: "BI", dialCode: "+257"},
    {code: "KH", dialCode: "+855"},
    {code: "CM", dialCode: "+237"},
    {code: "CA", dialCode: "+1"},
    {code: "CV", dialCode: "+238"},
    {code: "CF", dialCode: "+236"},
    {code: "TD", dialCode: "+235"},
    {code: "CL", dialCode: "+56"},
    {code: "CN", dialCode: "+86"},
    {code: "CO", dialCode: "+57"},
    {code: "KM", dialCode: "+269"},
    {code: "CG", dialCode: "+242"},
    {code: "CR", dialCode: "+506"},
    {code: "HR", dialCode: "+385"},
    {code: "CU", dialCode: "+53"},
    {code: "CY", dialCode: "+357"},
    {code: "CZ", dialCode: "+420"},
    {code: "DK", dialCode: "+45"},
    {code: "DJ", dialCode: "+253"},
    {code: "DO", dialCode: "+1-809"},
    {code: "EC", dialCode: "+593"},
    {code: "EG", dialCode: "+20"},
    {code: "SV", dialCode: "+503"},
    {code: "GQ", dialCode: "+240"},
    {code: "ER", dialCode: "+291"},
    {code: "EE", dialCode: "+372"},
    {code: "ET", dialCode: "+251"},
    {code: "FJ", dialCode: "+679"},
    {code: "FI", dialCode: "+358"},
    {code: "FR", dialCode: "+33"},
    {code: "GA", dialCode: "+241"},
    {code: "GM", dialCode: "+220"},
    {code: "GE", dialCode: "+995"},
    {code: "DE", dialCode: "+49"},
    {code: "GH", dialCode: "+233"},
    {code: "GR", dialCode: "+30"},
    {code: "GT", dialCode: "+502"},
    {code: "GN", dialCode: "+224"},
    {code: "GW", dialCode: "+245"},
    {code: "GY", dialCode: "+592"},
    {code: "HT", dialCode: "+509"},
    {code: "HN", dialCode: "+504"},
    {code: "HU", dialCode: "+36"},
    {code: "IS", dialCode: "+354"},
    {code: "IN", dialCode: "+91"},
    {code: "ID", dialCode: "+62"},
    {code: "IR", dialCode: "+98"},
    {code: "IQ", dialCode: "+964"},
    {code: "IE", dialCode: "+353"},
    {code: "IL", dialCode: "+972"},
    {code: "IT", dialCode: "+39"},
    {code: "CI", dialCode: "+225"},
    {code: "JM", dialCode: "+1-876"},
    {code: "JP", dialCode: "+81"},
    {code: "JO", dialCode: "+962"},
    {code: "KZ", dialCode: "+7"},
    {code: "KE", dialCode: "+254"},
    {code: "KI", dialCode: "+686"},
    {code: "KW", dialCode: "+965"},
    {code: "KG", dialCode: "+996"},
    {code: "LA", dialCode: "+856"},
    {code: "LV", dialCode: "+371"},
    {code: "LB", dialCode: "+961"},
    {code: "LS", dialCode: "+266"},
    {code: "LR", dialCode: "+231"},
    {code: "LY", dialCode: "+218"},
    {code: "LI", dialCode: "+423"},
    {code: "LT", dialCode: "+370"},
    {code: "LU", dialCode: "+352"},
    {code: "MG", dialCode: "+261"},
    {code: "MW", dialCode: "+265"},
    {code: "MY", dialCode: "+60"},
    {code: "MV", dialCode: "+960"},
    {code: "ML", dialCode: "+223"},
    {code: "MT", dialCode: "+356"},
    {code: "MR", dialCode: "+222"},
    {code: "MU", dialCode: "+230"},
    {code: "MX", dialCode: "+52"},
    {code: "MD", dialCode: "+373"},
    {code: "MC", dialCode: "+377"},
    {code: "MN", dialCode: "+976"},
    {code: "ME", dialCode: "+382"},
    {code: "MA", dialCode: "+212"},
    {code: "MZ", dialCode: "+258"},
    {code: "MM", dialCode: "+95"},
    {code: "NA", dialCode: "+264"},
    {code: "NR", dialCode: "+674"},
    {code: "NP", dialCode: "+977"},
    {code: "NL", dialCode: "+31"},
    {code: "NZ", dialCode: "+64"},
    {code: "NI", dialCode: "+505"},
    {code: "NE", dialCode: "+227"},
    {code: "NG", dialCode: "+234"},
    {code: "KP", dialCode: "+850"},
    {code: "NO", dialCode: "+47"},
    {code: "OM", dialCode: "+968"},
    {code: "PK", dialCode: "+92"},
    {code: "PW", dialCode: "+680"},
    {code: "PA", dialCode: "+507"},
    {code: "PG", dialCode: "+675"},
    {code: "PY", dialCode: "+595"},
    {code: "PE", dialCode: "+51"},
    {code: "PH", dialCode: "+63"},
    {code: "PL", dialCode: "+48"},
    {code: "PT", dialCode: "+351"},
    {code: "QA", dialCode: "+974"},
    {code: "RO", dialCode: "+40"},
    {code: "RU", dialCode: "+7"},
    {code: "RW", dialCode: "+250"},
    {code: "WS", dialCode: "+685"},
    {code: "SM", dialCode: "+378"},
    {code: "SA", dialCode: "+966"},
    {code: "SN", dialCode: "+221"},
    {code: "RS", dialCode: "+381"},
    {code: "SC", dialCode: "+248"},
    {code: "SL", dialCode: "+232"},
    {code: "SG", dialCode: "+65"},
    {code: "SK", dialCode: "+421"},
    {code: "SI", dialCode: "+386"},
    {code: "SB", dialCode: "+677"},
    {code: "SO", dialCode: "+252"},
    {code: "ZA", dialCode: "+27"},
    {code: "KR", dialCode: "+82"},
    {code: "SS", dialCode: "+211"},
    {code: "ES", dialCode: "+34"},
    {code: "LK", dialCode: "+94"},
    {code: "SD", dialCode: "+249"},
    {code: "SR", dialCode: "+597"},
    {code: "SE", dialCode: "+46"},
    {code: "CH", dialCode: "+41"},
    {code: "SY", dialCode: "+963"},
    {code: "TW", dialCode: "+886"},
    {code: "TJ", dialCode: "+992"},
    {code: "TZ", dialCode: "+255"},
    {code: "TH", dialCode: "+66"},
    {code: "TL", dialCode: "+670"},
    {code: "TG", dialCode: "+228"},
    {code: "TO", dialCode: "+676"},
    {code: "TT", dialCode: "+1-868"},
    {code: "TN", dialCode: "+216"},
    {code: "TR", dialCode: "+90"},
    {code: "TM", dialCode: "+993"},
    {code: "UG", dialCode: "+256"},
    {code: "UA", dialCode: "+380"},
    {code: "AE", dialCode: "+971"},
    {code: "GB", dialCode: "+44"},
    {code: "UY", dialCode: "+598"},
    {code: "UZ", dialCode: "+998"},
    {code: "VU", dialCode: "+678"},
    {code: "VA", dialCode: "+379"},
    {code: "VE", dialCode: "+58"},
    {code: "VN", dialCode: "+84"},
    {code: "YE", dialCode: "+967"},
    {code: "ZM", dialCode: "+260"},
    {code: "ZW", dialCode: "+263"},
];

interface PhoneNumberInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    label: string;
    placeholder?: string;
    error?: boolean;
    phoneNumber?: string;
    setPhoneNumber?: (phoneNumber: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
                                                               value, onChange, id, label, placeholder, error, setPhoneNumber, phoneNumber, ...props
                                                           }) => {
    const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleCountryChange = (country: Country) => {
        setSelectedCountry(country);
        setDropdownIsOpen(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value;
        if (setPhoneNumber) setPhoneNumber(formattedValue);
        onChange(e);
    };

    useEffect(() => {
        if (phoneNumber) {
            const foundCountry = countries.find(country => phoneNumber.startsWith(country.dialCode));
            if (foundCountry) {
                setSelectedCountry(foundCountry);
            }
        }
    }, [phoneNumber]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col items-start gap-[0.625rem] w-full">
            <label
                htmlFor={id}
                className="block text-slate-800 text-sm font-semibold dark:text-white">
                {label}
            </label>

            <div className="flex relative w-full">
                <div className="relative w-32">
                    <button
                        type="button"
                        className="flex items-center gap-x-2 pl-3 pr-2 py-2 rounded-r-none border border-r-0 border-gray-200 dark:border-neutral-700 rounded-l-md bg-white dark:bg-transparent w-full"
                        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
                    >
                        <Flag code={selectedCountry.code} className="w-6 h-6 rounded-full object-cover" alt={`${selectedCountry.code} flag`} />
                        <span className="text-sm font-medium text-gray-700 dark:text-white">{selectedCountry.code}</span>
                    </button>

                    {dropdownIsOpen && (
                        <div
                            ref={dropdownRef}
                            className="absolute border border-gray-200 top-full mt-1 w-full z-10 bg-white dark:bg-neutral-900 rounded-xl   max-h-36 overflow-y-auto"
                        >
                            <div className="p-1">
                                {countries.map((country) => (
                                    <button
                                        key={country.code}
                                        type="button"
                                        className="flex items-center gap-x-3 py-1.5 px-3 w-full text-sm text-gray-800 hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 focus:outline-none"
                                        onClick={() => handleCountryChange(country)}
                                    >
                                        <Flag code={country.code} className="w-6 h-6 rounded-full" alt={`${country.code} flag`} />
                                        <span>{country.code}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <InputMask
                    id={id}
                    mask={`${selectedCountry.dialCode} (999) 999-9999`}
                    className={` w-full py-2.5 px-3 block focus:outline-cyan-400 border  text-gray-500 rounded-lg rounded-r-md text-sm font-light placeholder:text-gray-400
                        disabled:opacity-50 disabled:pointer-events-none rounded-l-none
                        dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60
                        dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-gray-200"}`}
                    placeholder={placeholder}
                    type="text"
                    value={phoneNumber}
                    onChange={handleChange}
                    {...props}
                />
            </div>
        </div>
    );
};

export default PhoneNumberInput;
