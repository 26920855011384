export interface UserHrDocumentInterface {
    id: string;
    name?: string;
    status?: string;
    completedBy?: string;
    expirationDate?: string;
    notes?: string;
    attachment?: any;
    attachmentUrl?: string;
    deleteAttachment?: boolean;
    attachmentSize?: number;

}

export class UserHrDocument implements UserHrDocumentInterface {
    id: string;
    name: string;
    status: string;
    completedBy?: string;
    expirationDate?: string;
    notes?: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;
    deleteAttachment?: boolean;

    constructor(data: Partial<UserHrDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.status = data.status!;
        this.expirationDate = data.expirationDate!;
        this.completedBy = data.completedBy!;
        this.notes = data.notes!;
        this.attachment = data.attachment!;
        this.attachmentUrl = data.attachmentUrl!;
        this.attachmentSize = data.attachmentSize!;
        this.deleteAttachment = data.deleteAttachment!;
    }

    toJson(): UserHrDocumentInterface {
        return {
            id: this.id,
            notes: this.notes,
            attachment: this.attachment,
            deleteAttachment: this.deleteAttachment,
        };
    }
}
