// TextInputProperties.tsx
import React from 'react';
import {TextInputElement} from 'types/documentTemplateElement';
import TextInput from 'components/inputs/TextInput';
import RadioInputHorizontal from "../inputs/RadioInputHorizontal";
import RadioInput from "../inputs/RadioInput";

interface TextInputPropertiesProps {
    element: TextInputElement;
    updateElement: (updatedElement: TextInputElement) => void;
}

const TextInputProperties: React.FC<TextInputPropertiesProps> = ({
                                                                     element,
                                                                     updateElement,
                                                                 }) => {
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {id, value, type} = e.target;
        updateElement({
            ...element,
            [id]: value,
        });
    };

    return (
        <div className="space-y-4">
            <RadioInput
                label="Show Label"
                options={[
                    {id: 'show-label-yes', label: 'Yes', value: true},
                    {id: 'show-label-no', label: 'No', value: false},
                ]}
                selectedValue={element.showLabel}
                onChange={(value) => updateElement({...element, showLabel: value})}
            />
            {element.showLabel && (
                <TextInput
                    id="label"
                    type="input"
                    label="Label Text"
                    value={element.label}
                    onChange={handleChange}
                />
            )}
            <TextInput
                id="placeholder"
                label="Placeholder"
                type="input"
                value={element.placeholder || ''}
                onChange={handleChange}
            />
            <RadioInputHorizontal
                label="Required"
                options={[
                    {id: 'required-yes', label: 'Yes', value: true},
                    {id: 'required-no', label: 'No', value: false},
                ]}
                selectedValue={element.required}
                onChange={(value) => updateElement({...element, required: value})}
            />
        </div>
    );
};

export default TextInputProperties;
